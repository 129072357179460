@import '../../styles/theme.scss';

.page {
   padding: 50px 0px 150px 0px;
   max-width: 100%;
   overflow: hidden;
}

@media screen and (max-width: 991.98px) {
   .page {
      padding: $base-md;
      padding-bottom: $base * 2;
      max-width: 100%;
      overflow: hidden;

      .container {
         padding: 0px;
         margin: 0px;
         min-width: 100%;
      }
   }
}
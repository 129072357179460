@import './colors.scss';
@import './texts.scss';
@import './fonts.scss';
@import './animations.scss';
@import './sizes.scss';

// bg-colors
.bg-primary {
   background-color: $primary !important;
}

.bg-secondary {
   background-color: $secondary !important;
}

.bg-tertiary {
   background-color: $tertiary !important;
}

.bg-base {
   background-color: $base !important;
}

.bg-white {
   background-color: $white !important;
}

.bg-white80 {
   background-color: $white80 !important;
}

.bg-grey {
   background-color: $grey !important;
}

// text-colors
.text-primary {
   color: $primary !important;
}

.text-grey-dark {
   color: $grey-dark !important;
}

.text-secondary {
   color: $secondary !important;
}

.text-tertiary {
   color: $tertiary !important;
}

.text-base {
   color: $base !important;
}

.text-white {
   color: $white !important;
}

.text-white80 {
   color: $white80 !important;
}

.text-grey {
   color: $grey !important;
}

h6 {
   @extend .text-22;
   color: $text;
}

.heading {
   @extend .text-26;
}

.sub-heading {
   @extend .text-22;
}

.title {
   @extend .text-18;
   margin-bottom: 10px;
}

.desc {
   @extend .text-16;
   font-weight: 400;
   color: $grey;
   line-height: 26px;

   .bold {
      font-weight: 700;
   }
}

.desc.bold {
   @extend .desc;
   font-weight: 700;
}

.desc.semibold {
   font-weight: 500 !important;
}

.card {
   background-color: $white;
   padding: 30px;
   min-height: calc(100% - $h6-card-height);
   border-radius: 5px;
   border-top-left-radius: 0px;
   border-top-right-radius: 0px;
   box-shadow: $shadow;
   border: none;

   div {
      display: flex;
      flex-direction: row;
      margin-bottom: 7px;

      div {
         margin-top: 4px;
         min-width: 30px;
      }
   }
}

.icon-wrapper {
   min-width: 20px;
   margin-top: 5px;
}

.link-btn {
   color: $secondary;
   margin: 0px;
   cursor: pointer !important;
   background-color: unset !important;
}

.link-btn:hover {
   background-color: $secondary !important;
   color: $white;
}

.link-btn-no-bg {
   color: $secondary;
   margin: 0px;
   cursor: pointer !important;
   background-color: unset !important;
   opacity: 0.9;
   padding-left: 0px;
   padding-right: 0px;
   gap: 10px !important;
}

.link-btn-no-bg:focus,
.link-btn-no-bg:active {
   outline: 0 !important;
   border: 0 !important;
}

.link-btn-no-bg:hover {
   color: $secondary !important;
   opacity: 1;
}

.link-btn.danger {
   color: $error;
}

.link-btn.danger:hover {
   background-color: $error !important;
   color: $white;
}


.link-btn-no-bg.disabled {
   cursor: not-allowed !important;
   outline: none;
   border-color: transparent;
}

.link-btn.disabled {
   opacity: 0.6;
   // background-color: $grey !important;
   border-color: transparent;
   cursor: not-allowed;
}

._quill {
   .ql-toolbar {
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
   }

   .ql-container {
      height: calc(100% - 44px) !important;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
   }
}

._quill.quill-error {
   .ql-toolbar {
      border-top-color: red !important;
      border-left-color: red !important;
      border-right-color: red !important;
   }

   .ql-container {
      border-color: red !important;
   }
}

.blink {
   animation: blink-animation 1.5s steps(5, start) infinite;
   -webkit-animation: blink-animation 1.5s steps(5, start) infinite;
}

@keyframes blink-animation {
   to {
      visibility: hidden;
   }
}

@-webkit-keyframes blink-animation {
   to {
      visibility: hidden;
   }
}

@media screen and (max-width: 991.98px) {
   .card {
      padding: $base-md;
      min-height: unset;

      div {
         display: flex;
         flex-direction: row;
         margin-bottom: 7px;

         div {
            margin-top: 4px;
            min-width: 30px;
         }
      }
   }
}
@import '../../styles/theme.scss';
$sidebar-width-min: 85px;
$sidebar-width-max: 250px;
$transition: 0.3s ease-in-out;

.sidebar {
   .offcanvas {
      overflow: hidden;
      border: 0px;
      box-shadow: $shadow;
      width: $sidebar-width-min;
      transition: $transition;

      .offcanvas-header {
         background-color: $primary;
         height: 70px;

         .heading {
            display: none;
         }
      }

      .offcanvas-body {
         background-color: $white;
         padding: 0px;
         overflow: scroll;

         .nav-bar {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            margin: 0px;
            padding: 0px;

            .nav-item {
               list-style-type: none;
               border-bottom: 1.5px solid $white80;

               a {
                  @extend .text-16;
                  font-weight: 400;
                  color: $base;
                  padding: 15px;
                  margin: 0px;
                  display: flex;
                  align-items: center;
                  text-decoration: none;
                  width: 100%;

                  .i {
                     margin-right: 0px;
                  }

                  span {
                     display: none;
                  }
               }

               a.active,
               a:hover {
                  color: $secondary;
                  background-color: $white80;
               }
            }

            .nav-item {
               position: relative;

               .dropdown {
                  display: none;
                  flex-direction: column;
                  position: absolute;
                  left: $sidebar-width-min + 3px;
                  top: 0px;
                  background-color: $white;
                  z-index: 9999;
                  padding: 0px;
                  box-shadow: $shadow;
               }
            }

            .nav-item:hover {
               height: unset;
               background-color: $white80;

               .dropdown {
                  display: flex;

                  .nav-item {

                     a {
                        white-space: nowrap;
                     }
                  }
               }
            }
         }
      }
   }

   // Expanded
   .offcanvas.expanded {
      width: $sidebar-width-max;
      transition: $transition;

      .offcanvas-header {

         .heading {
            display: flex;
            animation: fadeIn 2s;
         }
      }

      .offcanvas-body {
         padding: 0px;
         overflow: scroll;

         .nav-bar {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            margin: 0px;
            padding: 0px;

            .nav-item {
               list-style-type: none;
               border-bottom: 1.5px solid $white80;

               a {
                  @extend .text-16;
                  font-weight: 400;
                  color: $base;
                  padding: 15px 20px;
                  margin: 0px;
                  display: flex;
                  align-items: center;
                  text-decoration: none;
                  justify-content: unset;

                  .i {
                     margin-right: 20px;
                  }

                  span {
                     display: flex;
                     // animation: fadeIn 2s;
                  }
               }

               a.active,
               a:hover {
                  color: $secondary;
                  background-color: $white80;
               }
            }

            .nav-item {
               .dropdown {
                  display: none;
                  flex-direction: unset;
                  position: unset;
                  left: unset;
                  top: unset;
                  background-color: unset;
                  z-index: unset;
                  padding: unset;
                  padding-left: 20px;
                  box-shadow: unset;
               }
            }

            .nav-item:hover {
               height: unset;

               .dropdown {
                  display: flex;
                  flex-direction: column;

                  .nav-item {}
               }
            }
         }
      }
   }

   .children {
      padding-left: $sidebar-width-min;
      transition: $transition;

      .admin-header {
         position: fixed;
         left: $sidebar-width-min;
         transition: $transition;
         right: 0px;
         z-index: 1;
      }
   }

   .children.expanded {
      padding-left: $sidebar-width-max;
      transition: $transition;

      .admin-header {
         left: $sidebar-width-max;
         transition: $transition;
         top: 0;
      }
   }

   .outlet {
      height: 100%;
      width: 100%;
      padding: 0px 50px 50px;
   }
}
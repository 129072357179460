@import '../../styles/theme.scss';

.footer {
   width: 100%;
   max-width: 100%;
   overflow: hidden;
   background-color: $secondary;

   .top-wrapper {
      padding: 100px 213px 85px 100px;
      display: flex;
      flex-direction: row;
      width: 100%;

      .links-wrapper {
         padding-left: 118px;
         display: flex;
         flex-direction: row;


         .navbar-nav {
            width: 40%;

            .nav-item {
               display: flex;
               justify-content: flex-start;

               .nav-link:hover {
                  color: $primary;
                  text-decoration: underline;
               }
            }
         }
      }

      .logo-div {
         display: flex;
         flex-direction: column;
         gap: 20px;

         .desc {
            @extend .text-24;
            color: $white;
            font-weight: 600;
            line-height: 28px;
         }
      }

      .navbar-nav {
         h6 {
            @extend .text-18;
            color: $white;
            font-weight: 700;
            line-height: 21px;
            margin-bottom: 20px;
            opacity: 0.8;
         }

         .nav-link {
            @extend .text-16;
            color: $white;
            font-weight: 700;
            line-height: 19px;
         }
      }

      input {
         padding: 16px 32px;
         width: 325px;
         height: 50px;
         background: rgba(253, 252, 252, 0.3);
         border: none;
         border-radius: 25px;
         color: $white;
      }

      input::placeholder {
         font-family: 'Poppins';
         font-style: normal;
         font-weight: 500;
         font-size: 16px;
         line-height: 24px;
         display: flex;
         align-items: center;
         color: #EBEBEB;
      }

      input:focus {
         outline: none;
      }

      .btn {
         margin-top: 20px;
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
         padding: 17px 32px;
         gap: 5px;
         width: 213px;
         height: 50px;
         background: $primary;
         border-radius: 100px;

         div {
            @extend .text-20;
            line-height: 23px;
            color: #FDFCFC;
         }
      }
   }

}

.bottom-wrapper {
   padding: 35px 100px;
   border-top: 1px solid $white;
   display: flex;
   justify-content: space-between;

   p {
      @extend .text-16;
      font-weight: 400;
      color: $white;

      span {
         font-weight: bold;
      }
   }

   div {
      gap: 8px;
   }
}

@media screen and (max-width: 991.98px) {
   .footer {
      .top-wrapper {
         padding: $base;

         .links-wrapper {
            padding: 0px $base;
            display: flex;
            flex-direction: column;
            gap: 20px;


            .navbar-nav {
               width: 40%;

               .nav-item {
                  display: flex;
                  justify-content: flex-start;

                  .nav-link:hover {
                     color: $primary;
                     text-decoration: underline;
                  }
               }
            }
         }

         input {
            width: 100%;
         }

      }
   }

   .bottom-wrapper {
      padding: $base;
      border-top: 1px solid $white;
   }
}
@import '../../../styles/theme.scss';

.admin-header {
   background-color: $white;
   padding: $base - 20px 19px;
   box-shadow: $shadow;
   height: 70px;
   display: flex;
   align-items: center;
   gap: 50px;
   justify-content: space-between !important;

   .icon {
      color: $grey-light;
      cursor: pointer;
   }

   .icon:hover {
      color: $primary;
   }

   .content {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
   }
}
@import '../../../../styles/theme.scss';

.admission-guidelines {
   .container {
      max-width: 767px;

      .center {
         text-align: center;
         font-weight: 700;
         margin-top: 20px;
      }

      .li {
         margin-left: 5px;
      }

      h5 {
         font-weight: 700;
         margin-top: 60px;
         margin-bottom: 20px;
      }
   }
}

@media screen and (max-width: 991.98px) {}
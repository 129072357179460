@import '../../styles//theme.scss';

.auth-layout {
   background-color: $white;
   box-shadow: $shadow;
   padding: 0px 50px;
   height: 90px;
   display: flex;
   align-items: center;
}

.outlet {
   min-height: calc(100vh - 90px);
}
.text-84 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 84px;
   line-height: 104px;
   margin: 0px;
   color: $text;
}

.text-72 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 72px;
   line-height: 89px;
   margin: 0px;
   color: $text;
}

.text-60 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 60px;
   line-height: 75px;
   margin: 0px;
   color: $text;
}

.text-48 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 48px;
   line-height: 60px;
   margin: 0px;
   color: $text;
}

.text-42 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 42px;
   line-height: 49px;
   margin: 0px;
   color: $text;
}

.text-36 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 36px;
   line-height: 45px;
   margin: 0px;
   color: $text;
}

.text-32 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 32px;
   line-height: 38px;
   margin: 0px;
   color: $text;
}

.text-30 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 30px;
   line-height: 39px;
   margin: 0px;
   color: $text;
}

.text-26 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 26px;
   line-height: 36px;
   margin: 0px;
   color: $text;
}

.text-24 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 24px;
   line-height: 31px;
   margin: 0px;
   color: $text;
}

.text-22 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 700;
   font-size: 22px;
   margin: 0px;
   color: $text;
}

.text-20 {
   font-family: 'Roboto';
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 26px;
   margin: 0px;
   color: $text;
}

.text-18 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 600;
   font-size: 18px;
   line-height: 23px;
   margin: 0px;
   color: $text;
}

.text-16 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 21px;
   margin: 0px;
   color: $text;
}

.text-14 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   margin: 0px;
   color: $text;
}

.text-12 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 400;
   font-size: 12px;
   line-height: 14px;
}

.text-10 {
   // font-family: 'Raleway';
   font-style: normal;
   font-weight: 400;
   font-size: 10px;
   line-height: 13px;
   margin: 0px;
   color: $text;
}

.tex-pre-line {
   white-space: pre-line;
}

.table-text {
   font-weight: 400;
   font-size: 0.875rem;
   line-height: 1.43;
   letter-spacing: 0.01071em;
   vertical-align: inherit;
   text-align: left;
   color: $text;
   overflow: hidden;
   padding: 1rem;
   text-overflow: ellipsis;
   white-space: normal;
   z-index: 0;
   transition: all 150ms ease-in-out 0s;
   vertical-align: middle;
}

@media screen and (max-width: 991.98px) {
   .text-48 {
      // font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 42px;
      line-height: 49px;
      margin: 0px;
      color: $text;
   }

   .text-42 {
      // font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 32px !important;
      margin: 0px;
      color: $text;
   }

   .text-36 {
      // font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 38px;
      margin: 0px;
      color: $text;
   }

   .text-32 {
      // font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
      margin: 0px;
      color: $text;
   }

   .text-20 {
      // font-family: 'Raleway';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      margin: 0px;
      color: $text;
   }
}
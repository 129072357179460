@import '../../../styles/theme.scss';

.banner {
   position: relative;
   z-index: 0;

   h1 {
      @extend .text-42;
      color: $white80;
      text-transform: uppercase;
   }

   .header {
      position: relative;
      background: linear-gradient(60deg, $primary 0%, $primary 0%, $secondary 100%);
      color: $white;
   }

   .inner-header {
      height: 120px;
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      padding-bottom: 30px;
   }

   .flex {
      /*Flexbox for containers*/
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      text-align: left;
   }

   .waves {
      position: relative;
      width: 100%;
      margin-bottom: -7px;
      /*Fix for safari gap*/
      min-height: 70px;
      max-height: 70px;
   }

   /* Animation */
   .parallax>use {
      animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
   }

   .parallax>use:nth-child(1) {
      animation-delay: -2s;
      animation-duration: 7s;
   }

   .parallax>use:nth-child(2) {
      animation-delay: -3s;
      animation-duration: 10s;
   }

   .parallax>use:nth-child(3) {
      animation-delay: -4s;
      animation-duration: 13s;
   }

   .parallax>use:nth-child(4) {
      animation-delay: -5s;
      animation-duration: 20s;
   }

   @keyframes move-forever {
      0% {
         transform: translate3d(-90px, 0, 0);
      }

      100% {
         transform: translate3d(85px, 0, 0);
      }
   }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
   .banner {
      .inner-header {
         height: 80px;
         padding-bottom: 0px;
         display: flex;
         align-items: center;
      }

      .flex {
         .container {
            padding: $base;
         }
      }

      .waves {
         min-height: 50px;
         max-height: 50px;
      }

   }
}
._mt {
   margin-top: 100px;
}

._mt_50 {
   margin-top: 50px !important;
}

._mt_150 {
   margin-top: 150px;
}

$h6-card-height: 86.4px;
$h6-card-height-sm: 64.4px;

$base: 30px;
$base-md: 20px;
$base-sm: 15px;
$admin-table-base: 20px;

@media screen and (max-width: 991.98px) {
   ._mt {
      margin-top: 50px;
   }

   $base: 10px !important;
}

@media screen and (max-width: 991.98px) {
   ._mt {
      margin-top: 50px;
   }
}
@import '../../../../styles/theme.scss';

.academics-calendar {
   .container {}
}

@media screen and (max-width: 991.98px) {
   .academics-calendar {
      .container {
         img {
            width: 100%;
            object-fit: contain;
         }
      }
   }
}
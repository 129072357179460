@import '../../styles/theme.scss';

.header {
   position: relative;
   z-index: 1;

   .navbar-1 {
      position: fixed;
      left: 0px;
      right: 0px;
      top: 0px;
      z-index: 2;

      background-color: $secondary;
      width: 100%;
      height: 40px;
      padding: 0px;
      padding: 0px 100px;

      .navbar-collapse {
         min-height: 100%;

         .navbar-nav {
            height: 100%;
            margin-left: auto;
         }

         .dropdown {
            position: relative;

            >a {
               .arrow {
                  transition: 0.3s ease-in-out;
                  margin-left: 5px;
               }
            }

            .project {
               visibility: visible;

               .drop-item {
                  list-style-type: none;
                  min-width: 150px;

                  a {
                     white-space: nowrap;
                     font-weight: 400;
                     text-decoration: none;
                     height: 100%;
                     padding: 10px 20px;
                     margin: 0px;
                  }

                  a:after {
                     height: 0.5px;
                  }
               }

               .drop-item:hover {
                  color: $white;
                  background-color: $white-shadow;
               }
            }
         }

         .dropdown .project {
            position: absolute;
            left: 20px;
            top: 35.3px;
            transform: translateX(-20px) translateY(5px);
            box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
            background-color: $secondary;
            padding: 1px 0px;
            border-radius: 2px;
            height: 0px;
            overflow: hidden;
            visibility: hidden;
            transition: 0.2s ease-in-out;
         }

         .dropdown:hover {
            .project {
               height: 43px;
               visibility: visible;
            }

            .project.portal {
               height: 207px;
            }

            >a {
               color: $grey-light;

               .arrow {
                  color: $grey-light;
                  transform: rotate(180deg);
               }
            }
         }

         .nav-item {
            a {
               position: relative;
               @extend .text-14;
               color: $white;
               padding: 0px 20px;
               height: 40px;
               display: flex;
               align-items: center;
               font-weight: 600;
               text-decoration: none;

               .icon {
                  margin-right: 7px;
               }

               .icon-active {
                  display: none;
                  margin-right: 7px;
               }
            }

            a.active,
            a:hover {
               color: $grey-light;
               background-color: $white-shadow;

               .icon {
                  display: none;
               }

               .icon-active {
                  display: flex;
               }
            }
         }
      }
   }

   .navbar-2 {
      background-color: $white;
      width: 100%;
      height: 80px;
      padding: 0px 10px;
      border-bottom: 0.5px solid $grey-light;
      display: flex;
      justify-content: center;

      .container {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
      }

      .navbar-brand {
         display: flex;
         align-items: center;
      }

      .nav-bar {
         display: flex;
         height: 100%;
         margin: 0px;

         .nav-item {
            list-style-type: none;
            padding: 0px 0px;
         }

         .dropdown {
            position: relative;

            >a {
               .arrow {
                  transition: 0.3s ease-in-out;
                  margin-left: 5px;
               }
            }

            .project {
               visibility: visible;

               .drop-item {
                  list-style-type: none;
                  min-width: 150px;
                  position: relative;

                  a {
                     white-space: nowrap;
                     font-weight: 400;
                     text-decoration: none;
                     height: 100%;
                     padding: 10px 20px;
                     margin: 0px;
                  }

                  a:after {
                     // display: none;
                     height: 0.5px;
                  }

                  .sub-project {
                     position: absolute;
                     left: 100.1%;
                     top: 0px;
                     box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
                     background-color: $white;
                     padding: 1px 0px;
                     border-radius: 2px;
                     height: 0px;
                     overflow: hidden;
                     visibility: hidden;
                     transition: 0.2s ease-in-out;
                  }
               }

               .sub-dropdown {
                  >a {
                     .arrow {
                        margin-left: 10px;
                     }
                  }
               }

               .sub-dropdown:hover {
                  .sub-project {
                     height: 125px;
                     visibility: visible;
                  }

                  .academics {
                     height: auto !important;
                     min-width: 230px;

                     a {
                        white-space: pre-line;
                     }
                  }

                  >a {
                     color: $secondary;

                     .arrow {
                        transform: rotate(180deg);
                        margin-left: 10px;
                     }
                  }
               }


               .drop-item:hover {
                  color: $secondary;
                  background-color: $white80;
               }
            }
         }

         .dropdown .project {
            position: absolute;
            left: 25px;
            top: 75px;
            transform: translateX(-20px) translateY(5px);
            box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
            background-color: $white;
            padding: 1px 0px;
            border-radius: 2px;
            height: 0px;
            overflow: hidden;
            visibility: hidden;
            transition: 0.2s ease-in-out;
         }

         .dropdown:hover {
            .project {
               height: 123px;
               overflow: visible;
               visibility: visible;
            }

            .project.portal,
            .project.school,
            .project.admissions {
               height: 207px;
            }

            .project.academics,
            .project.admissions.showRes {
               height: 220px;
            }

            .project.school {
               height: 248px;
            }

            >a {
               color: $secondary;

               .arrow {
                  transform: rotate(180deg);
               }
            }
         }

         .nav-item {
            a {
               position: relative;
               @extend .text-16;
               font-weight: 400;
               color: $primary;
               padding: 0px 20px;
               margin: 0px 5px;
               height: 100%;
               display: flex;
               align-items: center;
               text-decoration: none;

               .i {
                  margin-right: 10px;
               }

               .icon {
                  margin-right: 7px;
               }

               .icon-active {
                  display: none;
                  margin-right: 7px;
               }
            }

            a:after {
               content: "";
               position: absolute;
               width: 0%;
               height: 2px;
               bottom: 0;
               left: 0;
               background-color: $secondary;
               transition: 0.3s ease-in-out;
            }

            a:hover:after {
               width: 100%;
               color: $secondary;
            }

            a.active,
            a:hover {
               color: $secondary;

               .icon {
                  display: none;
               }

               .icon-active {
                  display: flex;
               }
            }
         }
      }
   }

   .scroll {
      position: fixed;
      width: 100%;
      transition: top 0.2s;
   }

   .scroll.visible {
      top: 40px;
      z-index: 1;
   }

   .scroll.hidden {
      top: -100px;
      z-index: 1;
   }
}

// xxl
@media screen and (max-width: 1400.98px) {
   .header {
      .navbar-1 {
         padding: 0px 2%;

         .navbar-collapse {
            .dropdown {

               >a {
                  .arrow {
                     margin-left: 0px;
                  }
               }

               .project {
                  visibility: visible;

                  .drop-item {

                     a {
                        padding: 10px;
                     }
                  }
               }
            }

            .dropdown .project {
               visibility: hidden;
            }

            .nav-item {
               a {
                  padding: 0px 17px;
               }
            }

            .dropdown .project {
               right: -20px;
               left: unset;
               top: 35.3px;
               visibility: hidden;
            }
         }
      }

      .navbar-2 {
         padding: 0px 2%;
         margin: 0px;

         .container {
            margin: 0px;
            padding: 0px;
         }

         .nav-bar {
            .dropdown {

               .project {
                  visibility: visible;

                  .drop-item {
                     .sub-project {
                        visibility: hidden;
                     }
                  }


                  .sub-dropdown:hover {
                     .sub-project {
                        visibility: visible;
                     }
                  }
               }
            }

            .dropdown .project {
               visibility: hidden;
            }

            .nav-item {
               a {
                  padding: 0px 15px;
                  margin: 0px 5px;
               }
            }
         }
      }
   }
}


// xl
@media screen and (max-width: 1200.98px) {
   .header {
      .navbar-1 {
         padding: 0px 10px;

         .navbar-collapse {

            .dropdown .project {
               visibility: hidden;
            }

            .nav-item {
               a {
                  padding: 0px 7.7px;
               }
            }
         }
      }

      .navbar-2 {
         padding: 0px 10px;

         .container {
            min-width: 100%;
         }

         .nav-bar {
            padding: 0px;

            .dropdown {

               .project {
                  visibility: visible;

                  .drop-item {
                     .sub-project {
                        visibility: hidden;
                     }
                  }
               }
            }

            .dropdown .project {
               visibility: hidden;
            }

            .nav-item {
               a {
                  padding: 0px 10px;
                  margin: 0px 0px;

                  .i {
                     margin-right: 10px;
                  }

                  .icon {
                     margin-right: 5px;
                  }

                  .icon-active {
                     margin-right: 5px;
                  }
               }
            }
         }
      }
   }
}

// md
@media screen and (max-width: 991.98px) {

   .navbar {
      display: none;
   }

   .scroll {
      .navbar-2 {
         .navbar-brand {
            img {
               width: 150px;
               height: 45px;
            }
         }

         .nav-bar {
            display: none;
         }

         .menu-icon {
            display: flex;

            i {
               color: $text;
               margin-top: auto;
               margin-bottom: auto;
            }
         }
      }
   }

   .scroll.visible,
   .scroll.hidden {
      top: 0px !important;
   }

   .offcanvas {
      width: 80% !important;

      .offcanvas-header {
         background-color: $primary;
         color: $white;
      }

      .offcanvas-body {
         padding: 30px;

         .divider {
            height: 2px;
            width: 100%;
            background-color: $white80;
            margin: 20px 0px;
         }

         .nav-bar {
            display: flex;
            flex-direction: column;
            margin: 0px;
            padding: 0px;
            gap: 5px;

            .nav-item {
               list-style-type: none;
               padding: 0px;

               a {
                  font-style: normal;
                  font-weight: 700;
                  font-size: 14px;
                  margin: 0px;
                  color: $text;
                  position: relative;
                  font-weight: 400;
                  color: $primary;
                  padding: 10px 0px;
                  margin: 0px;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  text-decoration: none;

                  .i {
                     margin-right: 10px;
                  }

                  .icon {
                     margin-right: 7px;
                  }

                  .icon-active {
                     display: none;
                     margin-right: 7px;
                  }
               }

               a:after {
                  content: "";
                  position: absolute;
                  width: 0%;
                  height: 2px;
                  bottom: 0;
                  left: 0;
                  background-color: $secondary;
                  transition: 0.3s ease-in-out;
               }

               a:hover:after {
                  width: 100%;
                  color: $secondary;
               }

               a.active,
               a:hover {
                  color: $secondary;

                  .icon {
                     display: none;
                  }

                  .icon-active {
                     display: flex;
                  }
               }
            }

            .dropdown {
               position: relative;

               >a {
                  .arrow {
                     transition: 0.3s ease-in-out;
                     margin-left: 20px;
                  }
               }

               .project {
                  visibility: visible;

                  .drop-item {
                     list-style-type: none;
                     min-width: 150px;
                     position: relative;

                     a {
                        white-space: nowrap;
                        font-weight: 400;
                        text-decoration: none;
                        height: 100%;
                        padding: 10px 20px;
                        margin: 0px;
                     }

                     a:after {
                        // display: none;
                        height: 0.5px;
                     }

                     .sub-project {
                        position: absolute;
                        left: 5%;
                        top: 44px;
                        box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
                        background-color: $white;
                        padding: 1px 0px;
                        border-radius: 2px;
                        height: 0px;
                        overflow: hidden;
                        visibility: hidden;
                        transition: 0.2s ease-in-out;
                        z-index: 2000;
                        width: 100%;
                     }
                  }

                  .sub-dropdown {
                     >a {
                        .arrow {
                           margin-left: 10px;
                        }
                     }
                  }

                  .sub-dropdown:hover {
                     .sub-project {
                        height: 134px;
                        visibility: visible;
                     }

                     .academics {
                        height: auto !important;
                        min-width: auto;

                        a {
                           white-space: pre-line;
                        }
                     }

                     >a {
                        color: $secondary;

                        .arrow {
                           transform: rotate(180deg);
                           margin-left: 10px;
                        }
                     }
                  }


                  .drop-item:hover {
                     color: $secondary;
                     background-color: $white80;
                  }
               }
            }

            .dropdown .project {
               position: absolute;
               left: 20px;
               top: 44px;
               transform: translateX(-20px) translateY(5px);
               box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
               background-color: $white;
               padding: 1px 0px;
               border-radius: 2px;
               height: 0px;
               overflow: hidden;
               visibility: hidden;
               transition: 0.2s ease-in-out;
               z-index: 999;
               width: 100%;
            }

            .dropdown:hover {
               .project {
                  height: 134px;
                  overflow: visible;
                  visibility: visible;
               }

               .project.admissions {
                  height: 210px;
               }

               .project.portal,
               .project.admissions.showRes  {
                  height: 248px !important;
               }

               .project.school,
               .project.academics {
                  height: 251px;
               }

               >a {
                  color: $secondary;

                  .arrow {
                     transform: rotate(180deg);
                  }
               }
            }

         }
      }
   }
}
$primary: #79c853;
$primary-light: rgba(67, 180, 13, 0.5);
$secondary: rgba(20, 109, 169, 1);
$white-shadow: rgba(255, 255, 255, 0.1);
$tertiary: rgba(74, 58, 143, 1);
$base: rgba(43, 41, 38, 1);
$white: rgba(253, 252, 252, 1);
$white80: rgba(240, 249, 247, 1);
$grey: rgba(0, 0, 0, 0.65);
$grey-dark: rgba(0, 0, 0, 0.75);
$text: rgba(0, 0, 0, 0.8);
$inputBg: #FAFAFC;
$grey-lighter: rgba(211, 211, 211, 0.5);
$grey-light: lightgray;

$error: #d33;

$gradient-primary: linear-gradient(124.51deg, #79c853 50%, #FFFFFF 109.42%);
$gradient-secondary: linear-gradient(124.51deg, #408ECC 50%, #FFFFFF 109.42%);
$gradient-2: linear-gradient(126.81deg, #79c853 17.04%, #79c853 45.46%, #53B7ED 67.42%, #56A4D8 86.03%);
$gradient-3: linear-gradient(126.51deg, #408ECC 27.94%, #FFFFFF 173.44%);

$gradient-bar-primary: linear-gradient(126.51deg, #79c853 27.94%, #FFFFFF 173.44%);
$gradient-bar-secondary: linear-gradient(126.51deg, #408ECC 27.94%, #FFFFFF 173.44%);

$shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
@import '../../styles/theme.scss';

.button-component.btn {
   margin-top: auto;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 0px 20px;
   gap: 5px;
   background: $primary !important;
   border-radius: 100px !important;
   height: 40px !important;
   border: none !important;

   div {
      @extend .text-16;
      line-height: 16px;
      color: #FDFCFC;
      margin: 0px !important;
   }
}

.button-component.btn.secondary {
   background: $secondary !important;
}

.button-component.btn:disabled {
   opacity: 0.5;
   border: none;
}

.button-component.btn:hover {
   background: $primary !important;
   box-shadow: $shadow;
}

.button-component.btn.secondary:hover {
   background: $secondary !important;
}

.button-component.btn.cancel {
   background: rgba($color: $grey, $alpha: 0.1) !important;

   div {
      color: $text;
   }
}
.fade-in-animation {
   animation: fadeIn 2s;
   -webkit-animation: fadeIn 2s;
   -moz-animation: fadeIn 2s;
   -o-animation: fadeIn 2s;
   -ms-animation: fadeIn 2s;

   @keyframes fadeIn {
      0% {
         opacity: 0;
      }

      100% {
         opacity: 1;
      }
   }

   @-moz-keyframes fadeIn {
      0% {
         opacity: 0;
      }

      100% {
         opacity: 1;
      }
   }

   @-webkit-keyframes fadeIn {
      0% {
         opacity: 0;
      }

      100% {
         opacity: 1;
      }
   }

   @-o-keyframes fadeIn {
      0% {
         opacity: 0;
      }

      100% {
         opacity: 1;
      }
   }

   @-ms-keyframes fadeIn {
      0% {
         opacity: 0;
      }

      100% {
         opacity: 1;
      }
   }
}

.right-to-left-animation {
   position: relative;
   animation: right-to-left 0.5s;
   -webkit-animation: right-to-left 0.5s;
   -moz-animation: right-to-left 0.5s;
   -o-animation: right-to-left 0.5s;
   -ms-animation: right-to-left 0.5s;

   @keyframes right-to-left {
      from {
         left: 30%;
         opacity: 0;
      }

      to {
         left: 0px;
         opacity: 1;
      }
   }
}

.left-to-right-animation {
   position: relative;
   animation: left-to-right 0.5s;
   -webkit-animation: left-to-right 0.5s;
   -moz-animation: left-to-right 0.5s;
   -o-animation: left-to-right 0.5s;
   -ms-animation: left-to-right 0.5s;

   @keyframes left-to-right {
      from {
         right: 30%;
         opacity: 0;
      }

      to {
         right: 0px;
         opacity: 1;
      }
   }
}
.outlet {
   margin-top: 120px;
   height: 100%;
}

@media screen and (max-width: 991.98px) {
   .outlet {
      margin-top: 79.5px;
      height: 100%;
   }
}